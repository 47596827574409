"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const forms_1 = require("@angular/forms");
const email_service_1 = require("./email-service");
const i0 = require("@angular/core");
const i1 = require("./email-service");
class EmailValidatorDirective {
    constructor(emailService) {
        this.emailService = emailService;
        this.emailControl = null;
        this.email = '';
        this.required = false;
        this.id = "";
        this.name = "";
    }
    validate(control) {
        this.emailControl = control;
        this.email = this.emailControl.value;
        if (this.email && this.email.length > this.validLength) {
            this.emailControl.markAsTouched();
            this.makeFormInvalid();
            return { 'invalidLength': true };
        }
        this.makeFormValid();
        return null;
    }
    onBlur() {
        if (this.emailControl) {
            if (!this.email && this.required) {
                this.emailControl.setErrors({ 'emailrequired': true });
                this.makeFormInvalid();
                return;
            }
            else if (!this.email && !this.required) {
                this.emailControl.setErrors(null);
                this.makeFormValid();
                return;
            }
            // Email must not contain multiple @ symbol
            if (this.email.split('@').length > 2) {
                this.emailControl.setErrors({ 'multipleAt': true });
                this.makeFormInvalid();
                return;
            }
            // Email must contain @ symbol
            if (!this.email.includes('@')) {
                this.emailControl.setErrors({ 'missingAt': true });
                this.makeFormInvalid();
                return;
            }
            const domain = this.email.split('.');
            if (domain.length > 1) {
                // Email must contain domain name
                const firstPart = domain[0];
                if (firstPart[firstPart.length - 1] === '@') {
                    this.emailControl.setErrors({ 'missingDomain': true });
                    this.makeFormInvalid();
                    return;
                }
            }
            else {
                // Email must contain domain extension e.g. .com
                this.emailControl.setErrors({ 'missingDomainExtension': true });
                this.makeFormInvalid();
                return;
            }
            // Email must not contain spaces
            if (this.email.includes(' ')) {
                this.emailControl.setErrors({ 'invalidSpace': true });
                this.makeFormInvalid();
                return;
            }
            const invalidCharactersPattern = /[^a-zA-Z0-9._%+-@]/;
            // Email must not include special characters
            if (invalidCharactersPattern.test(this.email)) {
                this.emailControl.setErrors({ 'invalidCharacters': true });
                this.makeFormInvalid();
                return;
            }
            const emailPattern = /^[\w._%+-]+@[\w.-]+\.[a-zA-Z]+$/;
            // Email address must follow proper format
            if (!emailPattern.test(this.email)) {
                this.emailControl.setErrors({ 'invalidEmail': true });
                this.makeFormInvalid();
                return;
            }
            if (this.id && this.unique) {
                this.emailService.saveKeyValuePair(this.id, this.email);
                // Email address must be unique
                if (this.emailService.hasValueWithDifferentKeys(this.email)) {
                    this.emailControl.setErrors({ 'duplicateEmail': true });
                    this.makeFormInvalid();
                    return;
                }
            }
            if (this.email && this.email.length > this.validLength) {
                this.makeFormInvalid();
                this.emailControl.setErrors({ 'invalidLength': true });
                return;
            }
            // this.emailService.clearKeyValueStore();
            this.emailControl.setErrors(null);
            this.makeFormValid();
        }
    }
    makeFormInvalid() {
        if (this.saveForm && this.saveForm[this.name]) {
            this.saveForm[this.name].$setValidity('customError', false);
        }
    }
    makeFormValid() {
        if (this.saveForm && this.saveForm[this.name]) {
            this.saveForm[this.name].$setValidity('customError', true);
        }
    }
}
exports.EmailValidatorDirective = EmailValidatorDirective;
EmailValidatorDirective.ɵfac = function EmailValidatorDirective_Factory(t) { return new (t || EmailValidatorDirective)(i0.ɵɵdirectiveInject(i1.EmailService)); };
EmailValidatorDirective.ɵdir = i0.ɵɵdefineDirective({ type: EmailValidatorDirective, selectors: [["", "emailValidator", ""]], hostBindings: function EmailValidatorDirective_HostBindings(rf, ctx) { if (rf & 1) {
        i0.ɵɵlistener("blur", function EmailValidatorDirective_blur_HostBindingHandler() { return ctx.onBlur(); });
    } }, inputs: { required: "required", id: "id", unique: "unique", name: "name", validLength: "validLength", saveForm: "saveForm" }, features: [i0.ɵɵProvidersFeature([
            {
                provide: forms_1.NG_VALIDATORS,
                useExisting: core_1.forwardRef(() => EmailValidatorDirective),
                multi: true
            }
        ])] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(EmailValidatorDirective, [{
        type: core_1.Directive,
        args: [{
                selector: '[emailValidator]',
                providers: [
                    {
                        provide: forms_1.NG_VALIDATORS,
                        useExisting: core_1.forwardRef(() => EmailValidatorDirective),
                        multi: true
                    }
                ]
            }]
    }], function () { return [{ type: i1.EmailService }]; }, { required: [{
            type: core_1.Input
        }], id: [{
            type: core_1.Input
        }], unique: [{
            type: core_1.Input
        }], name: [{
            type: core_1.Input
        }], validLength: [{
            type: core_1.Input
        }], saveForm: [{
            type: core_1.Input
        }], onBlur: [{
            type: core_1.HostListener,
            args: ['blur']
        }] }); })();
