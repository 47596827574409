"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const i0 = require("@angular/core");
class EmailService {
    constructor() {
        this.keyValueStore = {};
    }
    // Method to save key-value pair using Object
    saveKeyValuePair(key, value) {
        this.keyValueStore[key] = value;
    }
    // Method to retrieve value by key using Object
    getValueByKey(key) {
        return this.keyValueStore[key];
    }
    // Method to check if keyValueStore contains duplicate value or not
    hasValueWithDifferentKeys(value) {
        const keys = Object.keys(this.keyValueStore);
        const values = Object.values(this.keyValueStore);
        const valueKeys = keys.filter(key => this.keyValueStore[key] === value);
        return valueKeys.length > 1;
    }
    // Clear the object
    clearKeyValueStore() {
        this.keyValueStore = {};
    }
}
exports.EmailService = EmailService;
EmailService.ɵfac = function EmailService_Factory(t) { return new (t || EmailService)(); };
EmailService.ɵprov = i0.ɵɵdefineInjectable({ token: EmailService, factory: EmailService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(EmailService, [{
        type: core_1.Injectable
    }], null, null); })();
